:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #707070;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 40;
  --color-primary-green: 51;
  --color-primary-blue: 81;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #102048;
  --color-primary-lightened: #3D5289;
  --color-secondary: #99D8F2;
  --color-light: #DDEDFF;

  --brand-danger: #99D8F2;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #00B060;
  --brand-warning: #FFA400;
  --brand-success-light: #4ac68e;


  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-headings: 'Lato', sans-serif;
  --font-family-handwriting: 'Indie Flower', cursive;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #4A90E2;
  --link-color--hover: #175DAF;

  /* Global Alert */
  --global-alert-height: 3rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */

  --color-election-center-bg: #302f2f;
  
  /* Share Scene */
  --share-image-background: #326272;
  --share-image-text: #e39674;
  --share-image-label-text: #ffffff;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}
